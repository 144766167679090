<template>
  <div>
    <div>
      <van-swipe class="my-swipe"
        :autoplay="3000"
        indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/banner.jpg"
            alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="my-list">
      <van-grid :border="false"
        :column-num="3"
        class="wktype">
        <van-grid-item @click="gotoUrl('/pension/dangAn')">
          <img src="../../assets/dangan.png"
            alt=""
            class="iconimg" />
          <span class="wktext">健康档案</span>
        </van-grid-item>
        <van-grid-item @click="gotoUrl('/pension/tiJianJiLv')">
          <img src="../../assets/jilu.png"
            alt=""
            class="iconimg" />
          <span class="wktext">体检记录</span>
        </van-grid-item>
        <van-grid-item @click="gotoUrl('/pension/shangBao')">
          <img src="../../assets/shangbao.png"
            alt=""
            class="iconimg" />
          <span class="wktext">健康上报</span>
        </van-grid-item>
        <!-- <van-grid-item @click="gotoUrl('/pension/gerenXx')">
          <img src="../../assets/geren.png"
            alt=""
            class="iconimg" />
          <span class="wktext">个人信息</span>
        </van-grid-item> -->
      </van-grid>
    </div>
    <div style="padding: 20px 0 0 20px">
      <div class="biankuang"></div>
      <h3 style="
            display: initial;
            padding-left: 10px;
            font-family: PingFang SC;
            font-weight: 500;
          ">
        养生知识
      </h3>
      <span style="position: absolute;right: 35px;color: #999999;"
        @click="gotoUrl('/pension/ysZhiShi')">更多＞</span>
    </div>
    <div class="information"
      style="width:95%;margin:0 auto;">
      <div class="center"
        v-for="item in NoticeList"
        :key="item.index">
        <div @click="gotoUrl('/pension/zsXiangQing/' + item.Id)">
          <!-- <router-link to="'/pension/zsXiangQing/' + item.Id"> -->
          <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
          <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img v-if="item.CoverImgUrl"
                :src="item.CoverImgUrl"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3"
                style="font-size:16px;color:black;">{{ item.Title }}</span>
              <br />
              <span class="centertime">{{ item.AddTime }}</span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </div>
      <van-empty description="暂无养生知识"
        v-if="!NoticeList.length" />
    </div>
  </div>
</template>
<script>
import { WeGetArticleList } from "@/api/Pension";
export default {
  data () {
    return {
      NoticeList: [],
      zanwu: false
    }
  },
  created () {
    this.getNoticeList()
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getNoticeList () {
      WeGetArticleList({ type: '01', page: 1, limit: 5 }).then((res) => {
        this.NoticeList = res.data.data;
      });
    },
    more: function (path) {
      this.$router.push("/pension/ysZhiShi/");
    },
    // gotoUrl: function (id) {
    //   this.$router.push("/pension/zsXiangQing/" + id);
    // }
  }
};
</script>
<style scoped>
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-list {
  width: 95%;
  margin: 15px auto;
  font-size: 14px;
  background-color: #f7f7f7;
}
.my-list img {
  width: 50%;
  margin-bottom: 10px;
}
.biankuang {
  border: 2px solid #ff9d30ff;
  background-color: #ff9d30ff;
  width: 0;
  height: 20px;
  float: left;
  border-radius: 2px;
}
</style>